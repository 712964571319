.BoardAreaHeader {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.BoardAreaHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.react-kanban-column {
  min-height: 70vh !important;
  background: #e5e5e5 !important;
  padding: 10px !important;
  border-radius: 6px !important;
  margin: 10px !important;
}

.board-droppable {
  max-width: 90vw !important;
  padding: 40px;
}

.BoardContent {
  /* height: calc(100% - 65px); */
  overflow: auto;
  /* padding: 40px 0; */
  background: #eee;
}

.BoardContent::-webkit-scrollbar,
.react-kanban-board::-webkit-scrollbar {
  display: none;
}

.BoardContent,
.react-kanban-board {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-kanban-column {
  width: 360px !important;
}

.react-kanban-column-header {
  user-select: none !important;
  pointer-events: none !important;
}

.BoardHeaderSlider {
  justify-content: center;
  align-items: center;
  width: 350px;
  padding: 10px 0 0 20px;
}
