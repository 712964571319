.OrderDetail {
  min-width: 400px;
  max-width: 400px;
  margin-left: -400px;
  transition: all 200ms ease-in-out;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
}

.OrderDetail.isOpen {
  margin-left: 0;
}

.OrderDetailHeader {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.OrderDetailHeader svg {
  transform: translate(0, 4px);
}

.OrderDetailContent {
  padding: 15px;
  overflow: auto;
  flex: 1;
}

/* custom scrollbar */
.OrderDetailContent::-webkit-scrollbar {
  width: 15px;
}

.OrderDetailContent::-webkit-scrollbar-track {
  background-color: transparent;
}

.OrderDetailContent::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 15px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.OrderDetailContent::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.OrderDetailContent .space,
.OrderFormDialog .space {
  height: 25px;
}

.OrderDetailItem {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  flex-direction: row;
}

.OrderDetailItem p:first-of-type {
  font-weight: 500 !important;
  color: black;
  min-width: 130px;
}

.OrderDetailItem:not(.whats) p:last-of-type {
  padding-left: 5px;
}

.whatsappDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
}

.whatsappDiv span {
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.whatsappButton {
  background: #25d366;
  color: white;
  border: none;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  font-size: 1rem;
  display: flex;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
}

.ItemDeleteButton {
  border: none;
  font-size: large;
  cursor: pointer;
  background: #f50056b4;
  color: white;
  transition: all 200ms ease-in-out;
  border-radius: 100px;
  width: 25px;
  height: 25px;
}

.ItemDeleteButton.disabled,
.ItemDeleteButton.disabled:hover {
  cursor: not-allowed;
  background: #f50056b4;
}

.OrderDetailProduct.removed .ItemDeleteButton {
  background: #000000;
  color: white;
}

.ItemDeleteButton svg {
  font-size: 1.05rem;
  transform: translate(4px, 2px);
}

.ItemDeleteButton:hover {
  background: #f50057;
}

.OrderDetailProduct {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  flex-direction: column;
  position: relative;
  padding: 20px 20px 10px;
  background: #3f51b517;
  border-radius: 5px;
  margin-bottom: 25px;
}

.OrderDetailProduct.removed {
  background: #dfdfdf;
  opacity: 0.7;
}

.OrderDetailProduct > .MuiBox-root {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.OrderDetailProduct > .MuiBox-root:nth-child(1) {
  display: flex;
  gap: 8px;
}

.OrderDetailProduct > .MuiBox-root:nth-child(1) p {
  margin-top: 5px;
}

.OrderDetailProduct > .MuiBox-root:nth-child(2),
.OrderDetailProduct > .MuiBox-root:nth-child(3) {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
}

.OrderDetailProduct > a {
  position: absolute;
  top: 10px;
  right: 10px;
}

.OrderDetailProduct > .MuiBox-root:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.OrderDetailProduct > .MuiBox-root:nth-child(4) .MuiChip-root {
  display: flex;
  align-items: center;
  min-width: 20%;
  padding: 0 5px;
}

.OrderDetailProduct > .MuiBox-root:nth-child(4) .MuiChip-root div {
  flex: 1;
  text-align: center;
  padding: 0 5px;
}

.OrderDetailProduct > .MuiBox-root:nth-child(4) svg {
  cursor: pointer;
}

.OrderDetailProduct.removed > .MuiBox-root:nth-child(4) svg {
  cursor: not-allowed;
}

.OrderDetailProduct > .MuiBox-root:nth-child(4) svg.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.OrderDetailFooter {
  padding: 15px;
  display: flex;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  justify-content: center;
}

.OrderDetail-img-wrapper {
  position: relative;
  background-color: #fff;
  border-radius: 0.5rem;

  width: 70px;
  height: 100px;
}

.OrderDetail-img {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.OrderFormDialog .MuiDialog-paper {
  max-height: 90vh;
  height: 90vh;
}

.SectionDeliverySettings {
  padding: 15px;
  border: dashed 1px #9fa8da;
  border-radius: 5px;
}
