.AddTaskTextField {
  width: 100%;
  padding-bottom: 30px;
}

.AddTaskButton {
  border-radius: 40px;
}

.AddTaskCard {
  background: transparent;
  border: none !important;
  padding: 20px;
}

.AddTaskCardHeader {
  text-align: center;
}

.AddTaskCardAction {
  justify-content: end;
}

#demo-simple-select-label {
  transform: translate(14px, -6px) scale(0.75);
}

.AddTaskCardContent {
  padding: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  height: calc(90vh - 192px);
  width: 560px;
  overflow: auto;
  overflow-x: hidden;
}

.AddTaskCardContent::-webkit-scrollbar {
  width: 15px;
}

.AddTaskCardContent::-webkit-scrollbar-track {
  background-color: transparent;
}

.AddTaskCardContent::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 15px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.AddTaskCardContent::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.OrderFormTabs {
  margin-top: -30px;
  margin-bottom: 10px;
}

.OrderFormItem {
  width: 100%;
}
