.TaskCard {
  background: white;
  max-width: 300px;
  min-width: 300px;
  margin: 20px;
  /* border: 1px solid var(--color-border); */
  border: none !important;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.TaskCardHeader {
  padding: 20px 20px 10px;
  font-weight: 700;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.TaskCard.selected {
  box-shadow: 0 0 0px 3px inset #3f51b5;
}

.TaskDeleteButton {
  border: none;
  background: none;
  font-size: large;
  cursor: pointer;
  margin-top: -5px;
  color: #d7d7d7;
}

.TaskDeleteButton:hover {
  color: #3f51b5;
}

.TaskDeleteButton.opened {
  color: #3ebe1e;
}

.TaskDeleteButton.opened:hover {
  color: #46da21;
}

.TaskCardContent {
  padding: 10px 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}

.TaskCardContent > div {
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  line-height: 20px;
  pointer-events: none;
}

.TaskCardContent .MuiChip-root {
  align-self: flex-start;
  margin: 5px 5px 0 0;
}

.TaskCardFooter {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px 20px;
}

.TaskCardFooter .price {
  display: flex;
  flex-direction: column;
}

.line-through {
  text-decoration: line-through;
}

.timer {
  background-color: white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: solid 4px #3f51b5;
  display: flex;
  place-items: center;
  place-content: center;
  font-size: 0.75rem;
}
