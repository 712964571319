.AppBar {
  box-shadow: none;
  flex-grow: 1;
  white-space: nowrap;
}

.HeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.HeaderLeftContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.HeaderLeftContainer h5 {
  margin-right: 10px;
}

.HeaderIcon {
  margin-right: 5px;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.indicator-red {
  background-color: red;
}

.indicator-green {
  background-color: green;
}
